/* eslint-disable */
import HttpService from '../HttpService';
export default class ContractUnitService {
    constructor() {
      this._httpService = new HttpService('/contract/unit');
    }

    async GetUnitById(unitId) {
      return await this._httpService.get(`/${unitId}`);
    }

    async GetUnitByContractIdAndSubcontractId(contractId, subContractId) {
      return await this._httpService.get(`/?contractId=${contractId}&subContractId=${subContractId}`);
    }
 };