/* eslint-disable */
import HttpService from '../HttpService';
export default class ContractProductService {
    constructor() {
      this._httpService = new HttpService('/contract/contract_product');
    }

    async FindAll() {
      return await this._httpService.get('/');
    }

    async FindAllByFilters(params) {
      return await this._httpService.get(`${params}`);
    }

    async FindById(id) {
      return await this._httpService.get(`/${id}`);
  }
 };
