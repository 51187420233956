import ContractProductService from '@/services-http/contract/ContractProductService';
import Product2Service from '@/services-http/contract/Product2Service';

export default {
  methods: {
    async getPlanByIdMixin(planId) {
      if (planId) {
        this.plans = [];
        this.loadingPlans = true;
        const product2Service = new Product2Service();
        await product2Service.FindById(planId).then(async (response) => {
          if (response && response.data) {
            await this.plans.push(response.data);
            this.plans.forEach((element) => {
              this.plans.push({
                ...element,
                name: `${element.name} - ${element.code}`,
              });
            });
            this.loadingPlans = false;
          }
        }).catch(() => {
          this.loadingPlans = false;
        });
      }
    },
    async getPlanNameById(planId) {
      if (this.plans && this.plans.length > 0) {
        const planFound = this.plans.find((plan) => plan.id === planId);
        if (planFound) {
          this.planNameMixin = `${planFound.name}`;
        }
      }
    },
    async getPlansByEligibilityId(eligibilityObject) {
      let query = '';
      if (eligibilityObject && eligibilityObject.elegibility_id.contractEligibilities) {
        query = `?contractEligibilitiesIds=${eligibilityObject.elegibility_id.contractEligibilities}`;
      } else if (eligibilityObject && eligibilityObject.id) {
        query = `?contractEligibilitiesIds=${eligibilityObject.id}`;
      }
      await this.findPlansByEligibility(query);
    },
    async getPlansByAllEligibilities(eligibilities) {
      if (eligibilities) {
        const query = `?contractEligibilitiesIds=${eligibilities}`;
        await this.findPlansByEligibility(query);
      }
    },
    async findPlansByEligibility(query) {
      const contractProductService = new ContractProductService();
      this.loadingPlans = true;
      this.plans = [];
      await contractProductService.FindAllByFilters(query).then(async (response) => {
        if (response && response.data) {
          const plans = response.data;
          await plans.forEach((element) => {
            this.plans.push({
              ...element.plan_id,
              eligibility_id: element.eligibility_id,
              name: `${element.plan_id.name} - ${element.plan_id.code}`,
            });
          });
        }
        this.loadingPlans = false;
      }).catch(() => {
        this.loadingPlans = false;
      });
    },
  },
};
