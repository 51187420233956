/* eslint-disable */
import HttpService from '../HttpService';
export default class ContractEligibilityService {
    constructor() {
      this._httpService = new HttpService('/contract/contract-eligibility');
    }

    async FindAllByFilters(params) {
      return await this._httpService.get(`/contract/${params}`);
    }
 };
