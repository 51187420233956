<template>
  <v-container fluid>
    <v-row v-if="showHeaderPage">
      <v-col cols="12" md="3" align="start">
        <p class="title">Informações do Plano</p>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col cols="12" md="3">
        <label class="label">Contrato / Apólice</label>
        <v-tooltip bottom color="primary">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-autocomplete
                v-model="contractedPlan.contractId"
                :items="contracts"
                item-value="id"
                item-text="text"
                class="mt-2"
                placeholder="Selecione"
                outlined
                color="textPrimary"
                :loading="loadingContracts"
                disabled
              />
            </div>
          </template>
          <span>{{ captureContractText() }}</span>
        </v-tooltip>
      </v-col>
      <v-col cols="12" md="3">
        <label class="label">Sub-Contrato</label>
        <v-tooltip bottom color="primary">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-autocomplete
                v-model="contractedPlan.subContractId"
                :items="subContracts"
                item-text="text"
                item-value="id"
                class="mt-2"
                placeholder="Selecione"
                outlined
                color="textPrimary"
                :loading="loadingSubContracts"
                :disabled="isDetail"
                @input="loadEligibilities(); observerContractedPlan()"
                clearable
              />
            </div>
          </template>
        <span>{{ captureSubContractText() }}</span>
        </v-tooltip>
      </v-col>
      <v-col cols="12" md="3">
        <label class="label">Unidade</label>
        <v-row
          no-gutters
          class="mt-2">
          <v-col
            class="flex-grow-0 flex-shrink-0">
            <v-autocomplete
              class="rounded-r-0"
              v-model="contractedPlan.unit"
              :items="contractedPlan.units"
              @change="setUnit(contractedPlan, $event)"
              item-text="code"
              item-value="id"
              placeholder="Código"
              style="min-width: 100px;"
              maxlength="70"
              outlined
              color="textPrimary"
              :disabled="isDetail"
              validate-on-blur
              return-object
            />
          </v-col>
          <v-col
            class="flex-grow-1 flex-shrink-1">
            <v-text-field
              class="rounded-l-0"
              :value="contractedPlan.unitDescription || ''"
              placeholder="Descrição"
              maxlength="70"
              outlined
              color="textPrimary"
              disabled
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="showEligibility" cols="12" md="3">
        <label class="label">Elegibilidade</label>
        <v-tooltip bottom color="primary">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-autocomplete
                v-model="contractedPlan.eligibilityId"
                :items="eligibilities"
                item-text="elegibility_id.name"
                item-value="id"
                class="mt-2"
                placeholder="Selecione"
                outlined
                color="textPrimary"
                :loading="loadingEligibilities"
                :disabled="isDetail"
                @input="loadPlans(); contractedPlan.planId = ''; observerContractedPlan()"
                clearable
              />
            </div>
          </template>
          <span>{{ captureEligibilityText() }}</span>
        </v-tooltip>
      </v-col>
      <v-col cols="12" md="3">
        <label class="label">Plano</label>
        <v-tooltip bottom color="primary">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-autocomplete
                v-model="contractedPlan.planId"
                :items="plans"
                item-text="name"
                item-value="id"
                class="mt-2"
                placeholder="Selecione"
                outlined
                color="textPrimary"
                :loading="loadingPlans"
                @input="observerContractedPlan();"
                :disabled="isDetail"
                clearable
              />
            </div>
          </template>
          <span>{{ capturePlanText() }}</span>
        </v-tooltip>
        </v-col>
      <v-col v-if="showStartValidityDate" cols="12" md="3">
        <label class="label">Data Início de Vigência</label>
        <div class="d-flex flex-column">
          <v-menu
            v-model="validityDateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto">
            <template
              v-slot:activator="{ on, attrs }">
              <v-text-field
                class="mt-2"
                outlined
                :disabled="isDetail"
                v-model="contractedPlan.startValidityDate"
                append-icon="fas fa-calendar-alt"
                placeholder="DD/MM/YYYY"
                v-mask="'##/##/####'"
                color="textPrimary"
                v-bind="attrs"
                v-on="on"
                readonly
                clearable
                @click="getAllowedDates()"
                @input="saveValidatyDate(contractedPlan.validityDate); observerContractedPlan()"
                :rules="[rule.isDateValid, rule.endDateIsGreaterThanOrEqualToStartDate(admissionDateHolder, contractedPlan.validityDate, 'Data de vigência deve ser maior ou igual a data de admissão')]"
                validate-on-blur
              />
            </template>
            <AllowedDatePicker
              @date="updateStartValidityDate($event)"
              :allowedDates="allowedDates"
            />
          </v-menu>
        </div>
      </v-col>
      <v-col v-if="showEndValidityDate" cols="12" md="3">
        <label class="label">Data Fim de Vigência*</label>
        <div class="d-flex flex-column">
          <v-menu
            v-model="validityDateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto">
            <template
              v-slot:activator="{ on, attrs }">
              <v-text-field
                class="mt-2"
                outlined
                :disabled="isDetail"
                v-model="contractedPlan.endValidityDate"
                append-icon="fas fa-calendar-alt"
                placeholder="DD/MM/YYYY"
                v-mask="'##/##/####'"
                color="textPrimary"
                v-bind="attrs"
                v-on="on"
                @click="getAllowedDates()"
                readonly
                clearable
              />
            </template>
            <AllowedDatePicker
              @date="updateEndValidityDate($event)"
              :allowedDates="allowedDates"
            />
          </v-menu>
        </div>
      </v-col>
      <v-col v-if="showCancellationReason" cols="12" md="3">
        <label class="label">Motivo de Exclusão</label>
        <v-text-field
          v-model="contractedPlan.cancellationReason"
          class="mt-2"
          placeholder="Não informado"
          outlined
          color="textPrimary"
          :disabled="isDetail"
          validate-on-blur
        />
      </v-col>
      <v-col  v-if="showFamilyCode" cols="12" md="3">
        <label class="label">Código Familiar</label>
        <v-text-field
          outlined
          class="mt-2"
          v-model="contractedPlan.familyCode"
          v-mask="`${ formatter.maskLimiter(10) }`"
          color="textPrimary"
          placeholder="Informe código familiar"
          :disabled="isDisabledFamilyCode || isDetail"
        />
      </v-col>
      <v-col  v-if="showFamilyCodeSequential" cols="12" md="3">
        <label class="label">Sequencial</label>
        <v-text-field
          outlined
          class="mt-2"
          v-model="contractedPlan.familyCodeSequential"
          v-mask="`${ formatter.maskLimiter(5) }`"
          color="textPrimary"
          placeholder="Informe código sequencial"
          :disabled="isDisabledFamilyCodeSequential || isDetail"
        />
      </v-col>
      <v-col cols="12" md="3" v-if="showGrace">
        <label class="label">Carência</label>
        <v-autocomplete
          v-model="contractedPlan.hasGracePeriod"
          :items="graceItems"
          :disabled="isDetail || isRHProtegido"
          placeholder="Selecione"
          outlined
          color="textPrimary"
          clearable
          class="mt-2"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Rules from '@/shared/validators/formRules';
import Formatters from '@/shared/formatters/formatters';
import Product2Service from '@/services-http/contract/Product2Service';
import FinancialGroupsMixin from '@/shared/mixins/sdi/financialGroupsMixin';
import ContractsMixin from '@/shared/mixins/sdi/contractsMixin';
import EligibilitiesMixin from '@/shared/mixins/sdi/eligibilitiesMixin';
import PlansMixin from '@/shared/mixins/sdi/plansMixin';
import AllowedDatePicker from '@/components/AllowedDatePicker/AllowedDatePicker.vue';
import CarrierService from '@/services-http/contract/CarrierService';
import moment from 'moment';
import ContractUnitService from '@/services-http/contract/ContractUnitService';

export default {
  components: {
    AllowedDatePicker
  },

  data: () => ({
    moment: moment,
    validityDateMenu: null,
    validityDatePicker: null,
    carrierService: null,
    allowedDates: [],
    loadingFinancialGroups: false,
    loadingContracts: false,
    loadingSubContracts: false,
    loadingEligibilities: false,
    loadingPlans: false,
    isDisabledFamilyCode: false,
    isDisabledFamilyCodeSequential: false,
    isMounted: false,

    contractedPlan: {
      financialGroupId: null,
      contractId: null,
      subContractId: null,
      planId: null,
      familyCode: null,
      familyCodeSequential: null,
      cancellationReason: null,
      hasGracePeriod: null,
      unit: {
        id: null,
        code: null,
        description: null,
      },
      unitId: null,
      unitDescription: null,
      unitCode: null,
      contractUnitService: null,
    },
    graceItems: [
      { text: 'Sim', value: true },
      { text: 'Não', value: false },
    ],

    contractName: '-',
    planName: '-',

    financialGroups: [],
    contracts: [],
    subContracts: [],
    plans: [],
    eligibilitiesIds: [],
    eligibilities: [],
    dataPlans: {},
  }),

  props: {
    contractedPlanData: {
      type: Object,
      required: true,
    },
    admissionDateHolder: {
      type: String,
      required: false,
    },
    isDetail: {
      type: Boolean,
      required: true,
    },
    showHeaderPage: {
      type: Boolean,
      required: true,
    },
    showEligibility: {
      type: Boolean,
      required: true,
    },
    showStartValidityDate: {
      type: Boolean,
      required: true,
    },
    showEndValidityDate: {
      type: Boolean,
      required: true,
    },
    showCancellationReason: {
      type: Boolean,
      required: true,
    },
    showFamilyCode: {
      type: Boolean,
      required: true,
    },
    showFamilyCodeSequential: {
      type: Boolean,
      required: true,
    },
    showGrace: {
      type: Boolean,
      required: false,
    },
    isRHProtegido: {
      type: Boolean,
      required: false,
    }
  },

  watch: {
    contractedPlanData: {
      deep: true,
      handler(val) {
        if (val && !this.isMounted) {
          this.loadContractedPlanData();
          this.isMounted = true;
        }
      },
    },
    contractName: {
      deep: true,
      handler(val) {
        this.updateDataAndEmit({ contractName: val });
      },
    },
    planName: {
      deep: true,
      handler(val) {
        this.updateDataAndEmit({ planName: val });
      },
    },
  },

  mixins: [
    FinancialGroupsMixin,
    ContractsMixin,
    EligibilitiesMixin,
    PlansMixin,
  ],

  methods: {
    setUnit(item, event) {
      item.unitId = event ? event.id : '';
      item.unitCode = event ? event.code : '';
      item.unitDescription = event ? event.description : '';
    },
    async getAllowedDates() {
      const fullPath = this.$router.currentRoute.fullPath.toString();
      const movementTypeUrl = fullPath.match(/movementType=([^&]*)/)[1];
      await this.carrierService.GetAllowedDates([parseInt(this.contractedPlanData.contractId)], movementTypeUrl).then(response => {
        if (response.status === 200) {
          this.allowedDates = response.data;
        }
      })
    },
    updateStartValidityDate(value) {
      if (Boolean(this.contractedPlan)) {
        this.contractedPlan.startValidityDate = moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY');
        this.validityDateMenu = false;
      }
    },
    updateEndValidityDate(value) {
      if (Boolean(this.contractedPlan)) {
        this.contractedPlan.endValidityDate = moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY');
        this.validityDateMenu = false;
      }
    },
    updateDataAndEmit(data) {
      this.dataPlans = { ...this.dataPlans, ...data };
      this.$emit('getCombinedDataPlans', this.dataPlans);
    },
    async getUnitById(unitId) {
      await this.contractUnitService.GetUnitById(unitId)
      .then((response) => {
        this.contractedPlan.unit = {
          id: this.contractedPlanData.unitId ? response.data.id.toString() : null,
          code: this.contractedPlanData.unitCode ? response.data.code : null,
          description: this.contractedPlanData.unitDescription ? response.data.description : null,
        }
        this.contractedPlan.unitId = response.data.id.toString();
        this.contractedPlan.unitCode = response.data.code;
        this.contractedPlan.unitDescription = response.data.description;
      })
      .catch(() => {
        return null;
      });
    },
    async loadContractedPlanData() {
      this.contractedPlan = {
        financialGroupId: Number(this.contractedPlanData.financialGroupId),
        contractId: Number(this.contractedPlanData.contractId),
        subContractId: Number(this.contractedPlanData.subContractId),
        planId: Number(this.contractedPlanData.planId),
        startValidityDate: this.formatter.formatDate(this.contractedPlanData.startValidityDate),
        endValidityDate: this.formatter.formatDate(this.contractedPlanData.endValidityDate),
        eligibilityId: this.contractedPlanData.eligibilityId ? Number(this.contractedPlanData.eligibilityId) : null,
        familyCode: this.contractedPlanData.familyCode ? this.contractedPlanData.familyCode : null,
        familyCodeSequential: this.contractedPlanData.familyCodeSequential ? this.contractedPlanData.familyCodeSequential : null,
        cancellationReason: this.contractedPlanData.cancellationReason,
        hasGracePeriod: this.contractedPlanData.hasGracePeriod,
        unitId: this.contractedPlanData.unitId ? this.contractedPlanData.unitId : null,
        unitCode: this.contractedPlanData.unitCode ? this.contractedPlanData.unitCode : null,
        unitDescription: this.contractedPlanData.unitDescription ? this.contractedPlanData.unitDescription : null,
      };

      this.getUnitByContractIdAndSubcontractId(this.contractedPlan.contractId, this.contractedPlan.subContractId);

      if (this.contractedPlan.unitId) {
        await this.getUnitById(this.contractedPlan.unitId);
      }

      this.isDisabledFamilyCode = this.contractedPlan.familyCode !== null;
      this.isDisabledFamilyCodeSequential = this.contractedPlan.familyCodeSequential !== null;

      if (this.contractedPlan.contractId) {
        await this.getContractById(this.contractedPlan.contractId);
        await this.getSubContractsByContractId(this.contractedPlan.contractId);
      }
      await this.loadEligibilities();
    },
    async getUnitByContractIdAndSubcontractId(contractId, subcontractId) {
      this.contractedPlan.units = []
      await this.contractUnitService.GetUnitByContractIdAndSubcontractId(contractId, subcontractId)
      .then((response) => {
        if (response && response.data) {
          this.contractedPlan.units = response.data;
        }
      })
    },
    async loadEligibilities() {
      if (!this.contractedPlan.subContractId) {
        this.plans = [];
        this.contractedPlan.planId = null;
      } else {
        await this.getEligibilitiesByContractAndSubcontracts(this.contractedPlan.contractId, this.contractedPlan.subContractId, this.contractedPlan.eligibilityId);
        this.loadPlans();
      }
    },
    loadPlans() {
      this.plans = [];
      if (this.contractedPlan.eligibilityId) {
        const eligibilityFound = this.eligibilities.find((eligibility) => eligibility.id === this.contractedPlan.eligibilityId);
        this.getPlansByEligibilityId(eligibilityFound);
      } else if (this.contractedPlan.planId) {
        this.getPlanById();
      } else {
        this.getPlansByAllEligibilities(this.eligibilitiesIds);
      }
    },
    formatEligibility() {
      if ((this.plans && this.plans.length > 0) && this.contractedPlan.planId) {
        const planFound = this.plans.find((p) => p.id === this.contractedPlan.planId);
        this.contractedPlan.eligibilityPertainingPlan = planFound.eligibility_id;
      }
    },
    observerContractedPlan() {
      this.formatEligibility();
      this.$emit('observerContractedPlan', this.contractedPlan);
    },
    getPlanById() {
      if (this.contractedPlan.planId) {
        const product2Service = new Product2Service();
        product2Service.FindById(this.contractedPlan.planId).then((response) => {
          if (response && response.data) {
            const plans = [];
            plans.push(response.data);
            plans.forEach((element) => {
              this.plans.push({
                ...element,
                name: `${element.name} - ${element.code}`,
              });
            });
          }
        });
      }
    },
    saveValidatyDate(validatyDate) {
      if (validatyDate && validatyDate.length === 10) {
        this.$emit('saveValidatyDate', validatyDate);
      }
    },
    captureContractText() {
      let contractFound = '-';
      if ((this.contracts && this.contracts.length > 0) && (this.contractedPlan.contractId)) {
        contractFound = this.contracts.find((contract) => contract.id === Number(this.contractedPlan.contractId));
        if (contractFound && contractFound.text) {
          this.contractName = contractFound.text;
          return contractFound.text;
        }
      }
      return contractFound;
    },
    captureSubContractText() {
      let subContractFound = '-';
      if ((this.subContracts && this.subContracts.length > 0) && (this.contractedPlan.subContractId)) {
        subContractFound = this.subContracts.find((subContract) => subContract.id === Number(this.contractedPlan.subContractId));
        if (subContractFound && subContractFound.text) {
          return subContractFound.text;
        }
      }
      return subContractFound;
    },
    captureEligibilityText() {
      let eligibilityFound = '-';
      if ((this.eligibilities && this.eligibilities.length > 0) && (this.contractedPlan.eligibilityId)) {
        eligibilityFound = this.eligibilities.find((eligibility) => eligibility.id === Number(this.contractedPlan.eligibilityId));
        if (eligibilityFound && eligibilityFound.elegibility_id) {
          return eligibilityFound.elegibility_id.name;
        }
      }
      return eligibilityFound;
    },
    capturePlanText() {
      let planFound = '-';
      if ((this.plans && this.plans.length > 0) && (this.contractedPlan.planId)) {
        planFound = this.plans.find((plan) => plan.id === Number(this.contractedPlan.planId));
        if (planFound) {
          this.planName = planFound.name;
          return planFound.name;
        }
      }
      this.planName = planFound;
      return planFound;
    },
  },

  created() {
    this.rule = new Rules();
    this.formatter = new Formatters();
    this.carrierService = new CarrierService();
    this.contractUnitService = new ContractUnitService();
  },
};
</script>
