<template>
  <v-container fluid>
    <v-row v-if="showHeaderPage">
      <v-col cols="12" md="3" align="start">
        <p class="title">Dados Principais</p>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col cols="12" md="3">
        <label class="label">Grupo Financeiro</label>
        <v-tooltip bottom color="primary">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-autocomplete
                v-model="holder.financialGroupId"
                :items="financialGroups"
                item-text="name"
                item-value="id"
                class="mt-2"
                placeholder="Selecione"
                outlined
                readonly
                color="textPrimary"
                :loading="false"
                :disabled="isDetail"
              />
            </div>
          </template>
          <span>{{ captureFinancialGroupText() }}</span>
        </v-tooltip>
      </v-col>
      <v-col cols="12" md="3">
        <label class="label">CPF do Titular</label>
        <v-text-field
          v-model="holder.document"
          class="mt-2"
          placeholder="Informe o número"
          v-mask="'###.###.###-##'"
          outlined
          color="textPrimary"
          :disabled="isDetail"
          :rules="holder.document ? [rule.cpf] : []"
          validate-on-blur
          @input="observerHolderInformationData()"
        />
      </v-col>
      <v-col cols="12" md="3">
        <label class="label">Nome do Titular</label>
        <v-text-field
          v-model.trim="holder.name"
          class="mt-2"
          placeholder="Informe o nome"
          maxlength="70"
          outlined
          color="textPrimary"
          :disabled="isDetail"
          :rules="holder.name ? [rule.name, rule.validateIsAlphanumeric] : []"
          validate-on-blur
          @input="observerHolderInformationData()"
          @blur="holder.name = formatter.formatToTitleCase(holder.name)"
        />
      </v-col>
      <v-col cols="12" md="3">
        <label class="label">Centro de Custo</label>
        <v-row
          no-gutters
          class="mt-2">
          <v-col
            class="flex-grow-0 flex-shrink-0">
            <v-text-field
              class="rounded-r-0"
              v-model.trim="holder.capacityCode"
              placeholder="Código"
              style="min-width: 100px;"
              maxlength="70"
              outlined
              color="textPrimary"
              :disabled="isDetail"
              :rules="holder.capacityCode ? [rule.required] : []"
              validate-on-blur
              @blur="holder.capacityCode = formatter.formatToTitleCase(holder.capacityCode)"
              @input="['', null, undefined].some(item => item === holder.capacityCode) ? holder.capacityDescription = '' : false"
            />
          </v-col>
          <v-col
            class="flex-grow-1 flex-shrink-1">
            <v-text-field
              class="rounded-l-0"
              v-model.trim="holder.capacityDescription"
              placeholder="Descrição"
              maxlength="70"
              outlined
              color="textPrimary"
              :disabled="isDetail || ['', null, undefined].some(item => item === holder.capacityCode)"
              :rules="holder.capacityDescription ? [rule.required] : []"
              validate-on-blur
              @blur="holder.capacityDescription = formatter.formatToTitleCase(holder.capacityDescription)"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="3">
        <label class="label">Departamento</label>
        <v-text-field
        class="mt-2"
          v-model.trim="holder.department"
          placeholder="Informe o Departamento"
          maxlength="70"
          outlined
          color="textPrimary"
          :disabled="isDetail"
          :rules="holder.department ? [rule.required] : []"
          validate-on-blur
          @blur="holder.department = formatter.formatToTitleCase(holder.department)"
        />
      </v-col>
      <template v-if="showRegistrationPlate">
        <v-col cols="12" md="3">
          <label class="label">Matrícula do Titular</label>
          <v-text-field
            maxlength="50"
            v-model="holder.registrationPlate"
            class="mt-2"
            placeholder="Informe a matrícula"
            outlined
            color="textPrimary"
            :disabled="isDetail"
            @input="observerHolderInformationData()"
          />
        </v-col>
        <v-col cols="12" md="3">
          <label class="label">Complemento da Matrícula do Titular</label>
          <v-text-field
            maxlength="50"
            v-model="holder.registrationComplement"
            class="mt-2"
            placeholder="Informe o complemento"
            outlined
            color="textPrimary"
            :disabled="isDetail"
            @input="observerHolderInformationData()"
          />
        </v-col>
      </template>
      <v-col v-show="showCardNumber" cols="12" md="3">
        <label class="label">Carteirinha do Titular</label>
        <v-text-field
          maxlength="50"
          v-model="holder.cardNumber"
          class="mt-2"
          placeholder="Informe a carteirinha"
          outlined
          color="textPrimary"
          :disabled="isDetail"
          @input="observerHolderInformationData()"
        />
      </v-col>
      <v-col v-show="showCardNumber" cols="12" md="3">
        <label class="label">Carteirinha Congênere</label>
        <v-text-field
          maxlength="50"
          v-model="holder.congenericCard"
          class="mt-2"
          placeholder="Informe a carteirinha congênere"
          outlined
          color="textPrimary"
          :disabled="isDetail"
          @input="observerHolderInformationData()"
        />
      </v-col>
      <v-col v-if="showAdmissionDate" cols="12" md="3">
        <label class="label">Data de Admissão</label>
        <div class="d-flex flex-column">
          <v-text-field
            class="mt-2"
            outlined
            v-model="holder.admissionDate"
            :disabled="isDetail"
            append-icon="fas fa-calendar-alt"
            placeholder="DD/MM/YYYY"
            v-mask="'##/##/####'"
            @input="saveAdmissionDate(holder.admissionDate); observerHolderInformationData()"
            color="textPrimary"
            :rules="holder.admissionDate ? [rule.isAfterSpecificYear, rule.isDateValid, rule.requiredDate] : []"
            validate-on-blur
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FinancialGroupsMixin from '@/shared/mixins/sdi/financialGroupsMixin';
import Formatters from '@/shared/formatters/formatters';
import Rules from '@/shared/validators/formRules';

export default ({
  data: () => ({
    holder: {
      document: null,
      name: null,
      registrationPlate: null,
      registrationComplement: null,
      cardNumber: null,
      admissionDate: null,
      financialGroupId: null,
      department: null,
      capacityCode: null,
      capacityDescription: null,
    },
    financialGroups: [],
    financialGroupId: null,
  }),

  props: {
    holderInformationData: {
      type: Object,
      required: false,
    },
    isDetail: {
      type: Boolean,
      required: true,
    },
    showHeaderPage: {
      type: Boolean,
      required: true,
    },
    showAdmissionDate: {
      type: Boolean,
      required: true,
    },
    showRegistrationPlate: {
      type: Boolean,
      required: true,
    },
    showCardNumber: {
      type: Boolean,
      required: true,
    },
  },

  watch: {
    holderInformationData(val) {
      if (val) {
        this.loadBeneficiaryData();
      }
    },
  },

  mixins: [
    FinancialGroupsMixin,
  ],

  async mounted() {
    await this.verifyFreeMovement();
    this.loadBeneficiaryData();
    await this.getFinancialGroups();
  },

  methods: {
    captureFinancialGroupText() {
      let financialGroupFound = '-';
      if ((this.financialGroups && this.financialGroups.length > 0) && (this.holder.financialGroupId)) {
        financialGroupFound = this.financialGroups.find((financialGroup) => financialGroup.id === Number(this.holder.financialGroupId));
        if (financialGroupFound) {
          return financialGroupFound.name;
        }
      }
      return financialGroupFound;
    },
    async verifyFreeMovement() {
      if (this.$route.query.freeMovement && (this.$route.query.freeMovement === 'true' || this.$route.query.freeMovement === true)) {
        this.isFreeMovement = true;
      }
    },
    async loadBeneficiaryData() {
      if (sessionStorage.getItem('movementRecord')) {
        const movementRecord = JSON.parse(sessionStorage.getItem('movementRecord'));
        this.holder.name = movementRecord.holderName ? this.formatter.formatToTitleCase(movementRecord.holderName) : null;
        this.holder.document = movementRecord.holderDocumentNumber ? this.formatter.formatCpf(movementRecord.holderDocumentNumber) : null;
        this.holder.admissionDate = movementRecord.eventDate ? this.formatter.formatDate(movementRecord.eventDate) : null;
        this.holder.registrationPlate = movementRecord.registrationPlate ? movementRecord.registrationPlate : null;
        this.holder.registrationComplement = movementRecord.registrationComplement ? movementRecord.registrationComplement : null;
        this.holder.cardNumber = movementRecord.beneficiaryCard ? movementRecord.beneficiaryCard : null;
        this.holder.congenericCard = movementRecord.congenericCard ? movementRecord.congenericCard : null;
        this.holder.capacityCode = movementRecord.taxAllocationCode ? movementRecord.taxAllocationCode : null;
        this.holder.capacityDescription = movementRecord.taxAllocationDescription ? movementRecord.taxAllocationDescription : null;
        this.holder.department = movementRecord.costCenterDepartment ? movementRecord.costCenterDepartment : null;
        this.holder.financialGroupId = movementRecord.financialGroupId ? parseInt(movementRecord.financialGroupId) : null;
      } else {
        this.holder = this.holderInformationData;
        this.holder.name = this.holder.name ? this.formatter.formatToTitleCase(this.holder.name) : null;
        this.holder.document = this.holder.document ? this.formatter.formatCpf(this.holder.document) : null;
        this.holder.admissionDate = this.holder.admissionDate ? this.formatter.formatDate(this.holder.admissionDate) : null;
        this.holder.registrationPlate = this.holder.registrationPlate ? this.holder.registrationPlate : null;
        this.holder.registrationComplement = this.holder.registrationComplement ? this.holder.registrationComplement : null;
        this.holder.cardNumber = this.holder.cardNumber ? this.holder.cardNumber : null;
        this.holder.congenericCard = this.holder.congenericCard ? this.holder.congenericCard : null;
        this.holder.capacityCode = this.holder.capacityCode ? this.holder.capacityCode : null;
        this.holder.capacityDescription = this.holder.capacityDescription ? this.holder.capacityDescription : null;
        this.holder.department = this.holder.department ? this.holder.department : null;
        this.holder.financialGroupId = this.holder.financialGroupId ? parseInt(this.holder.financialGroupId) : null;
      }
    },
    observerHolderInformationData() {
      this.$emit('observerHolderInformationData', this.holder);
    },
    saveAdmissionDate(admissionDate) {
      if (admissionDate && admissionDate.length === 10) {
        this.$emit('saveAdmissionDate', admissionDate);
      }
    },
  },

  created() {
    this.formatter = new Formatters();
    this.rule = new Rules();
  },
});
</script>
