/* eslint-disable */
import HttpService from '../HttpService';
export default class Product2Service {
    constructor() {
      this._httpService = new HttpService('/contract/plan');
    }

    async FindById(id) {
        return await this._httpService.get(`/${id}`);
    }
 };
