import ContractEligibilityService from '@/services-http/contract/ContractEligibilityService';

export default {
  methods: {
    async getEligibilitiesByContractAndSubcontracts(contractId, subcContractId, eligibilitySelected) {
      if (contractId && subcContractId) {
        this.eligibilities = [];
        const contractEligibilityService = new ContractEligibilityService();
        this.loadingEligibilities = true;
        const query = `${contractId}?subcontractId=${subcContractId}`;

        try {
          const response = await contractEligibilityService.FindAllByFilters(query);

          if (response && response.data && response.data.length > 0) {
            this.eligibilitiesIds.push(...response.data.map((element) => element.id));
            this.eligibilities.push(...response.data);
          }

          this.filterEligibilitiesBySelection(eligibilitySelected);
        } catch (error) {
          console.error('Error:', error);
        } finally {
          this.loadingEligibilities = false;
        }
      }
    },
    filterEligibilitiesBySelection(eligibilitySelected) {
      const contractEligibilitiesMap = this.eligibilities.map((e) => ({
        key: e.elegibility_id.id,
        value: e.id,
      }));

      const uniqueRecords = this.eligibilities.reduce((acc, obj) => {
        const duplicatedIndex = acc.findIndex((it) => it.elegibility_id.id === obj.elegibility_id.id);
        if (duplicatedIndex === -1) {
          acc.push(obj);
        }
        return acc;
      }, []);

      uniqueRecords.forEach((record) => {
        const contractEligibilities = contractEligibilitiesMap.filter(({ key }) => key === record.elegibility_id.id).map(({ value }) => value);
        this.$set(record.elegibility_id, 'contractEligibilities', contractEligibilities);
      });

      this.eligibilities = uniqueRecords;

      this.eligibilities.forEach((eligibility) => {
        const found = eligibility.elegibility_id.contractEligibilities.includes(eligibilitySelected);
        if (found) {
          this.$set(eligibility, 'id', eligibilitySelected);
        }
      });
    },
  },
};
