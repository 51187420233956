<template>
  <v-date-picker
    v-model="date"
    :allowed-dates="dateAllowed"
    @change="changeDate"
    color="primary"
    :min="minDate"
  />
</template>

<script>

export default {
  name: 'AllowedDatePicker',

  data: () => ({ date: null }),

  props: {
    allowedDates: {
      type: Array,
      required: true,
    },
    minDate: {
      type: String,
      default: '1899-01-01'
    }
  },

  methods: {
    changeDate(date) {
      this.$emit('date', date);
    },

    dateAllowed(dateString) {
      if (this.allowedDates.length === 0) return true;
      const date = new Date(dateString);
      const formattedDate = date.toISOString().split('T')[0];
      return this.allowedDates.includes(formattedDate);
    },
  },
};
</script>
